import { Button } from '@mui/material';
import Invoice from "../PDFReporte/Invoice";
import { PDFDownloadLink, PDFViewer, StyleSheet } from "@react-pdf/renderer";

const invoiceData = {
  id: "5df3180a09ea16dc4b95f910",
  invoice_no: "201906-28",
  balance: "$2,283.74",
  company: "MANTRIX",
  email: "susanafuentes@mantrix.com",
  phone: "+1 (872) 588-3809",
  address: "922 Campus Road, Drytown, Wisconsin, 1986",
  trans_date: "2019-09-12",
  due_date: "2019-10-12",
  items: [
    {
      sno: 1,
      desc: "Monto Fijo",
      qty: 10.001,
      rate: 50.000,
      int: 2.00,
      perHa: "Si",

    },
    {
      sno: 2,
      desc: "Monto Fijo",
      qty: 50.001,
      rate: 100.000,
      int: 500,
      perHa: "No",
    },
    {
      sno: 3,
      desc: "Monto Fijo",
      qty: 10.001,
      rate: 200.000,
      int: 600,
      perHa: "No",
    },
  ],
  itemsDollar: [
    {
      sno: 1,
      desc: "Porcentual",
      qty: 0.00,
      rate: 30.000,
      int: 1.00,
      perHa: "No",

    },
    {
      sno: 2,
      desc: "Porcentual",
      qty: 30.001,
      rate: 100.000,
      int: 2.00,
      perHa: "No",
    },
  ],
  itemsConvenio: [
    {
      sno: 1,
      desc: "Monto Fijo",
      qty: 1,
      rate: 999.999999,
      int: 500,
      perHa: "No",

    },
  ],
};
const styles = StyleSheet.create({
  viewContainer: {
    width: '70%',
    height: 700,
    margin: 100
  },
});
const PDFMain = () => {
  return (
    <div>
      <div>
        <PDFDownloadLink document={<Invoice invoice={invoiceData} />} fileName="myfirstpdf.pdf">
          {({ loading, url, error, blob }) =>
            loading ? (
              <Button>Cargando Documento ...</Button>
            ) : (
              <Button>Descargar Ahora!</Button>
            )
          }
        </PDFDownloadLink>
      </div>
      <div>
        <PDFViewer style={styles.viewContainer}>
          <Invoice invoice={invoiceData} />
        </PDFViewer>
      </div>
    </div>
  );
};

export { PDFMain };
