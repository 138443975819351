import React from 'react';
import {Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import MyCustomFont from '../../fonts/Anton-Regular.ttf';


Font.register({
    family: 'AntonFamily',
    src: MyCustomFont
  })

const borderColor = '#FFFFFF'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#000000',
        backgroundColor: '#FFFFFF',
        textAlign: 'center',
        flexGrow: 1,
        borderBottomWidth: 1,
        height: 24,
        flexGrow: 1,
        alignItems:'center',
        display:'flex'
    },
    title: {
        width: '100%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontFamily: "AntonFamily",
        fontSize: 10,
        alignSelf:'center',
        display:'flex'
    },
  });

  const InvoiceTableHeader = ({title}) => (
    <View style={styles.container}>
        <Text style={styles.title}>{title}</Text>
    </View>
  );
  
  export default InvoiceTableHeader