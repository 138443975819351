import { useState } from 'react';
import { useParametrosService } from '../../../../service/parametros';
import { useParametros } from '../useParametros';
import { errorMessages } from '../../../../components/messages/commonMessages';

export const useFormulario = (onSave, onCancel, parametro, render) => {
    const [descripcion, setDescripcion] = useState(parametro !== '' ? parametro[0].dscParametro : '');
    const [valor, setValor] = useState(parametro !== '' ? parametro[0].valParametro : '');
    const [categoria, setCategoria] = useState(parametro !== '' ? parametro[0].categoria : '');
    const { post, put } = useParametrosService();
    const { getParametros } = useParametros();

    let values = {};
    const addParametros = async (values) => {
        post(values);
    }
    const updateParametros = async (idParametro, values) => {
        put(idParametro, values);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (descripcion && valor && categoria) {
            values.dscParametro = descripcion;
            values.valParametro = valor;
            values.categoria = categoria;
            values.usuCreacion = "esteban.vargas";
            if (render === 'Add') {
                await addParametros(values);
                await getParametros();

            } else {
                let idParametro = parametro[0].idParametro
                if (idParametro !== undefined) {
                    await updateParametros(idParametro, values);
                    await getParametros();
                }
            }
            onSave();
            // Limpiar el formulario después de agregar el parámetro
            setDescripcion('');
            setValor('');
            setCategoria('');
        } else {
            alert(errorMessages.FORM_ERROR);
        }
    };
    const updateCategoria = async (value) => {
        setCategoria(value)
    }
    const updaValor = async (value) => {
        setValor(value)
    }
    const updateDescripcion = async (value) => {
        setDescripcion(value)
    }
    return (
        { updaValor, updateDescripcion, updateCategoria, handleSubmit, descripcion, valor, categoria }
    )
};