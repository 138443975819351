import React, { useState } from 'react';
import { DataTable } from '../Table/Table.jsx';
import { Footer } from '../Footer/Footer';
import { successMessages, confirmationMessages } from '../messages/commonMessages';
import { CustomDialog } from '../messages/CustomDialog';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../App.css'
import { AlertDialog } from '../messages/AlertDialog.jsx';
import Tooltip from '@mui/material/Tooltip';


const Formulario = ({ onSave, onCancel, selectedRow, isView, isEdit }) => {
    const [tipoRecaudador, setTipoRecaudador] = useState(selectedRow !== '' ? selectedRow[0].tipoRecaudador : '');
    const [tipoError, setTipoError] = useState(selectedRow !== '' ? selectedRow[0].tipoError : '');
    const [codigoError, setCodigoError] = useState(selectedRow !== '' ? selectedRow[0].codigoError : '');
    const [descripcion, setDescripcion] = useState(selectedRow !== '' ? selectedRow[0].descripcion : '');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (tipoError && codigoError && descripcion && tipoRecaudador) {

            alert(successMessages.OPERATION_SUCCESS);

            setTipoError('');
            setCodigoError('');
            setDescripcion('');
            setTipoRecaudador('');
        } else {
            alert('Por favor, complete todos los campos antes de agregar el código de respuesta.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Facturador:
                    </label>
                    <Tooltip title="Facturador">
                        <select className='select-form' value={tipoRecaudador} onChange={(e) => setTipoRecaudador(e.target.value)} disabled={isView || isEdit ? true : null}>
                            <option value="">Seleccione</option>
                            <option value="recaudador1">CCSS</option>
                            <option value="recaudador2">RACSA</option>
                            <option value="recaudador3">JASEC</option>
                            <option value="recaudador4">FACTURADOR</option>
                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Descripción:
                    </label>
                    <Tooltip title="Descripción">
                        <input className='input-form' placeholder='Descripción' type="text" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Código Error:
                    </label>
                    <Tooltip title="Código Error">
                        <input className='input-form' placeholder='Código Error' type="text" value={codigoError} onChange={(e) => setCodigoError(e.target.value)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Tipo Error:
                    </label>
                    <Tooltip title="Tipo Error">
                        <input className='input-form' placeholder='Tipo Error' type="text" value={tipoError} onChange={(e) => setTipoError(e.target.value)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="button-form">
                    {!isView && (
                        <Tooltip title="Aceptar">
                            <button className="action-button" onClick={onSave}>Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};

const MantenimientoCodigoRespuesta = () => {
    const [selectedRows, setSelectedRows] = useState();
    const [tipoRecaudador, setTipoRecaudador] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();

    const columnas = [
        { field: 'descripcion', headerName: 'Descripción', flex: 1, },
        { field: 'codigoError', headerName: 'Código Error', flex: 1 },
        { field: 'tipoError', headerName: 'Tipo Error', flex: 1 },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button className="button-table" onClick={handleConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Modificar">
                        <button className="button-table" onClick={handleEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <button className="button-table" onClick={handleDelete}>
                            <DeleteIcon />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const rows = [
        { id: 1, codigoError: 'Comisión Convenios', tipoError: '456', descripcion: 'Trabajador Independiente', },
        { id: 2, codigoError: 'Comisión Convenios', tipoError: '123', descripcion: 'Asegurado Voluntario', },
        { id: 3, codigoError: 'Comisión Convenios', tipoError: '789', descripcion: 'Planillas', },
        { id: 4, codigoError: 'test1', tipoError: '012', descripcion: 'Prueba', },
        { id: 5, codigoError: 'Comisión Convenios', tipoError: '456', descripcion: 'Trabajador Independiente', },
        { id: 6, codigoError: 'Comisión Convenios', tipoError: '1234567891011', descripcion: 'Asegurado Voluntario', },
        { id: 7, codigoError: 'Comisión Convenios', tipoError: '789', descripcion: 'Planillas', },
        { id: 8, codigoError: 'test1', tipoError: '012', descripcion: 'Prueba', },

    ];

    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRows('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };

    const handleSave = () => {
        alert(successMessages.CHANGES_SAVED);
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
    };

    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };

    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };

    const handleDelete = () => {
        setIsDialogOpen(true);
        setIsDelete(true);
    };

    const handleConfirmationDelete = () => {
        handleCloseDialog();
        setIsDelete(false);
    };

    const handleGenerateReport = () => {
        alert(successMessages.DOWNLOAD_SUCCESSFUL);
    };

    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRows = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRows(selectedRows);
        console.log(selectedRows[0].descripcion);
    };

    return (
        <div className='content-wrapper'>
            <div className="wrapper-card">
                <h1>
                    Mantenimiento Códigos de Respuesta
                </h1>
                <div className='form-container'>
                    <div className="content">
                        <div className='form-container'>
                            <div className="title">
                                Parámetros
                            </div>
                            <div className="content-filter">
                                <div className="fila-form">
                                    <div className="columna-form">
                                        <label>
                                            Facturador:
                                        </label>
                                        <Tooltip title="Facturador">
                                            <select className='select-form' value={tipoRecaudador} onChange={(e) => setTipoRecaudador(e.target.value)}>
                                                <option value="">Seleccione el facturador</option>
                                                <option value="recaudador1">CCSS</option>
                                                <option value="recaudador2">RACSA</option>
                                                <option value="recaudador3">JASEC</option>
                                                <option value="recaudador4">FACTURADOR</option>
                                            </select>
                                        </Tooltip>
                                    </div>
                                    <div className="button-filter">
                                        <Tooltip title="Buscar">
                                            <button className="action-button" onClick={handleGenerateReport}>Buscar</button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-container'>
                            <div className="title">
                                Lista de Códigos de Respuesta
                            </div>
                            <div className="content">
                                <DataTable data={rows} columns={columnas} onRowSelectionChange={handleRowSelectionChange} />

                                <div className='button-action'>
                                    <Tooltip title="Agregar">
                                        <button className="action-button" onClick={handleAdd}>Agregar</button>
                                    </Tooltip>
                                    {isDialogOpen && isAdd && (
                                        <CustomDialog
                                            question={'Agregar Código de Respuesta'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isEdit && (
                                        <CustomDialog
                                            question={'Modificar Código de Respuesta'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} isEdit={isEdit} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isView && (
                                        <CustomDialog
                                            question={'Consultar Código de Respuesta'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} isView={isView} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isDelete && (
                                        <AlertDialog
                                            question={"Eliminar Código de Respuesta"}
                                            message={confirmationMessages.DELETE_CONFIRMATION_CODIGO}
                                            isOpen={isDialogOpen}
                                            onClose={handleCancel}
                                            onAccept={handleConfirmationDelete}
                                            onCancel={handleCancel}
                                        />
                                    )}
                                    <Tooltip title="Generar Reporte">
                                        <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { MantenimientoCodigoRespuesta };