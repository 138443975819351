import axios from 'axios';
const serverUrl = process.env.REACT_APP_SERVER_URL;
const api = axios.create({
    baseURL: serverUrl
});
api.interceptors.request.use((config) => {
    config.headers = {
        ...config.headers
    }
    return config;
});
export default api;