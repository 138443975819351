import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { successMessages } from '../../messages/commonMessages.jsx';
import { useOficinaService } from '../../../service/oficina.js';

export const useOficina = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [tipoFacturador, setTipoFacturador] = useState('');
    const [isDelete, setIsDelete] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [data, setData] = useState([]);
    const [facturadoresMap, setFacturadoresMap] = useState([]);
    const { get, patch, getFacturador } = useOficinaService();
    const [loading, setLoading] = useState(false);

    const getOficinas = async () => {
        try {
            setLoading(true);
            const data = await get(tipoFacturador);
            setData(data);
        } catch (error) {
            console.error('Error fetching oficinas:', error);
        } finally {
            setLoading(false);
        }
    }

    const getFacturadores = async () => {
        try{
            setLoading(true);
            const facturadoresMap = await getFacturador();
            setFacturadoresMap(facturadoresMap);

        }catch(error){
            console.error('Error fetching facturadores:', error);
        }finally{
            setLoading(false);
        }
    }

    const deleteOficina = async (idOficina) => {
        try {
            if (idOficina !== undefined) {
                await patch(idOficina);
                alert('Oficina eliminada correctamente');
                getOficinas();
            } else {
                alert('Error eliminando oficina oficina: ID no valido');
            }
        } catch (error) {
            alert('Error eliminando oficina oficina');
            console.error("Error eliminando oficina oficina:", error);
        }
    }
    const handleSave = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        getOficinas();
    };

    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRow('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };
    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };

    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };

    const handleDelete = () => {
        setIsDialogOpen(true);
        setIsDelete(true);
    };

    const handleConfirmationDelete = () => {
        let idOficina = selectedRow[0].idOficina;
        deleteOficina(idOficina);
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };

    const handleGenerateReport = () => {
        alert(successMessages.DOWNLOAD_SUCCESSFUL);
    };

    const handleSearch = () => {
        alert(successMessages.OPERATION_SUCCESS);
    };

    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRow = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRow(selectedRow);
        if (selectedRow.length > 0) {
            console.log(selectedRow[0].dscParametro);
        }
    };

    const updateFacturador = async (value) => {
        setTipoFacturador(value)
    }

    const columnas = [
        { field: 'id', headerName: 'Consecutivo', flex: 1, },
        { field: 'nombre', headerName: 'Nombre Oficina', flex: 1 },
        { field: 'tipoFacturador', headerName: 'Facturador', flex: 1 },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button className="button-table" onClick={handleConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Modificar">
                        <button className="button-table" onClick={handleEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <button className="button-table" onClick={handleDelete}>
                            <DeleteIcon />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];
    return (
        {
            handleRowSelectionChange, getOficinas, handleSave, handleCloseDialog, updateFacturador,
            columnas, isDialogOpen, isEdit, isView, isAdd, tipoFacturador,
            handleAdd, handleCancel, handleConfirmationDelete, handleGenerateReport, handleSearch, isDelete, selectedRow, data, getFacturadores, facturadoresMap, loading
        }
    )
};