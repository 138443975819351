import React from 'react';
import cognitoUtils from '../../lib/cognitoUtils'
const LoginPage = () => {
    return (
          <div className="login-container">
          <div className="login">
            <div className="pt-1 mb-4">
            <a className="btn btn-primary" href={cognitoUtils.getCognitoSignInUri()}>Iniciar Sesión</a>
            </div>
            </div>
          </div>
    );
};

export default LoginPage;