import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

const drawerWidth = 280;

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

export const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    background: 'rgb(28, 37, 54)',
    color: 'rgb(255, 255, 255)',
    WebkitOverflowScrolling: 'touch', /* Para mejorar la experiencia de desplazamiento en dispositivos táctiles */
    '&::-webkit-scrollbar': {
      width: 10, /* Ancho de la barra de desplazamiento */
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888', /* Color del "pulgar" de la barra de desplazamiento */
      borderRadius: '6px', /* Radio de borde del "pulgar" */
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgb(28, 37, 54)', /* Color de fondo de la barra de desplazamiento */
    },
  });
  
  export const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    background: 'rgb(28, 37, 54)',
    color: 'rgb(255, 255, 255)',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    WebkitOverflowScrolling: 'touch', /* Para mejorar la experiencia de desplazamiento en dispositivos táctiles */
    '&::-webkit-scrollbar': {
      width: 10, /* Ancho de la barra de desplazamiento */
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888', /* Color del "pulgar" de la barra de desplazamiento */
      borderRadius: '6px', /* Radio de borde del "pulgar" */
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgb(28, 37, 54)', /* Color de fondo de la barra de desplazamiento */
    },
  });
  
  export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );