import React, { useState } from 'react';
import { DataTable } from '../Table/Table.jsx';
import { Footer } from '../Footer/Footer.jsx';
import { successMessages, confirmationMessages } from '../messages/commonMessages.jsx';
import { CustomDialog } from '../messages/CustomDialog.jsx';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../App.css'
import { AlertDialog } from '../messages/AlertDialog.jsx';
import Tooltip from '@mui/material/Tooltip';


const Formulario = ({ onSave, onCancel, selectedRow, isView, isEdit }) => {
    const [moneda, setMoneda] = useState(selectedRow !== '' ? selectedRow[0].moneda : '');
    const [cuentaBancaria, setCuentaBancaria] = useState(selectedRow !== '' ? selectedRow[0].cuentaBancaria : '');
    const [codigo, setCodigo] = useState(selectedRow !== '' ? selectedRow[0].codigo : '');
    const [descripcion, setDescripcion] = useState(selectedRow !== '' ? selectedRow[0].descripcion : '');
    const [tipoComision, setTipoComision] = useState(selectedRow !== '' ? selectedRow[0].tipoComision : '');
    const [tipoConvenio, setTipoConvenio] = useState(selectedRow !== '' ? selectedRow[0].tipoConvenio : '');
    const [tipoPeriodicidad, setTipoPeriodicidad] = useState(selectedRow !== '' ? selectedRow[0].tipoPeriodicidad : '');
    const [hacienda, setHacienda] = useState(selectedRow !== '' ? selectedRow[0].hacienda : false);
    const [tipoFacturador, setTipoFacturador] = useState(selectedRow !== '' ? selectedRow[0].tipoFacturador : '');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (codigo && descripcion && cuentaBancaria && tipoComision && tipoConvenio && tipoPeriodicidad && hacienda && moneda) {

            alert(successMessages.OPERATION_SUCCESS);

            setCodigo('');
            setDescripcion('');
            setCuentaBancaria('');
            setTipoComision('');
            setTipoConvenio('');
            setTipoPeriodicidad('');
            setHacienda('');
            setMoneda('');
        } else {
            alert('Por favor, complete todos los campos antes de agregar el convenio de facturador.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Facturador:
                    </label>
                    <Tooltip title="Facturador">
                        <select className='select-form' value={tipoFacturador} onChange={(e) => setTipoFacturador(e.target.value)} disabled={isView || isEdit ? true : null}>
                            <option value="">Seleccione</option>
                            <option value="moneda1">CCSS</option>
                            <option value="moneda2">RACSA</option>
                            <option value="moneda3">JASEC</option>
                            <option value="moneda4">FACTURADOR</option>
                        </select>
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Código Interno:
                    </label>
                    <Tooltip title="Código Interno">
                        <input className='input-form' type="text" placeholder='Código Interno' value={codigo} onChange={(e) => setCodigo(e.target.value)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Descripción:
                    </label>
                    <Tooltip title="Descripción">
                        <input className='input-form' type="text" placeholder='Descripción' value={descripcion} onChange={(e) => setDescripcion(e.target.value)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Moneda:
                    </label>
                    <Tooltip title="Moneda">
                        <select className='select-form' value={moneda} onChange={(e) => setMoneda(e.target.value)} disabled={isView ? true : null}>
                            <option value="">Seleccione</option>
                            <option value="Colones">Colones</option>
                            <option value="Dólar">Dolares</option>
                            <option value="moneda3">Euros</option>
                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Tipo de Comisión:
                    </label>
                    <Tooltip title="Tipo de Comisión">
                        <select className='select-form' value={tipoComision} onChange={(e) => setTipoComision(e.target.value)} disabled={isView ? true : null} >
                            <option value="">Seleccione</option>
                            <option value="Comision1">Comisión 1</option>
                            <option value="Comision2">Comisión 2</option>
                            <option value="Comision3">Comisión 3</option>
                        </select>
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Cuenta Bancaria:
                    </label>
                    <Tooltip title="Cuenta Bancaria">
                        <input className='input-form' type="text" placeholder='Cuenta Bancaria' value={cuentaBancaria} onChange={(e) => setCuentaBancaria(e.target.value)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Tipo de Convenio:
                    </label>
                    <Tooltip title="Tipo de Convenio">
                        <select className='select-form' value={tipoConvenio} onChange={(e) => setTipoConvenio(e.target.value)} disabled={isView ? true : null}>
                            <option value="">Seleccione</option>
                            <option value="moneda1">Convenio 1</option>
                            <option value="moneda2">Convenio 2</option>
                            <option value="Dato">Convenio 3</option>
                        </select>
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Tipo de Periodicidad:
                    </label>
                    <Tooltip title="Tipo de Periodicidad">
                        <select className='select-form' value={tipoPeriodicidad} onChange={(e) => setTipoPeriodicidad(e.target.value)} disabled={isView ? true : null}>
                            <option value="">Seleccione</option>
                            <option value="moneda1">Mensual</option>
                            <option value="moneda2">Semestral 2</option>
                            <option value="moneda3">Semestral 3</option>
                            <option value="Dato">Semestral 4</option>
                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Factura Única:
                    </label>
                    <Tooltip title="Factura Única">
                        <input className='checkbox-form' type="checkbox" checked={hacienda} onChange={() => setHacienda(!hacienda)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="button-form">
                    {!isView && (
                        <Tooltip title="Aceptar">
                            <button className="action-button" onClick={onSave}>Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};

const MantenimientoConvenioFacturadores = () => {
    const [selectedRows, setSelectedRows] = useState();
    const [tipoFacturador, setTipoFacturador] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();

    const columnas = [
        { field: 'descripcion', headerName: 'Descripción', flex: 1, },
        { field: 'moneda', headerName: 'Moneda', flex: 1, },
        { field: 'tipoComision', headerName: 'Tipo de Comisión', flex: 1, },
        { field: 'cuentaBancaria', headerName: 'Cuenta Bancaria', flex: 1, },
        { field: 'codigo', headerName: 'Código Interno', flex: 1, },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button className="button-table" onClick={handleConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Modificar">
                        <button className="button-table" onClick={handleEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <button className="button-table" onClick={handleDelete}>
                            <DeleteIcon />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const rows = [
        { id: 1, tipoComision: 'Comision1', codigo: '456', descripcion: 'Trabajador Independiente', cuentaBancaria: '1234567891011', moneda: 'Dólar', tipoConvenio: 'Dato', tipoPeriodicidad: 'Dato', hacienda: true },
        { id: 2, tipoComision: 'Comisión2', codigo: '123', descripcion: 'Asegurado Voluntario', cuentaBancaria: '1234567891011', moneda: 'Dólar', tipoConvenio: 'Dato', tipoPeriodicidad: 'Dato', hacienda: false },
        { id: 3, tipoComision: 'Comisión3', codigo: '789', descripcion: 'Planillas', cuentaBancaria: '1234567891011', moneda: 'Dólar', tipoConvenio: 'Dato', tipoPeriodicidad: 'Dato', hacienda: true },
        { id: 4, tipoComision: 'Comision1', codigo: '012', descripcion: 'Prueba', cuentaBancaria: '1234567891011', moneda: 'Dólar', tipoConvenio: 'Dato', tipoPeriodicidad: 'Dato', hacienda: false },
        { id: 5, tipoComision: 'Comisión2', codigo: '456', descripcion: 'Trabajador Independiente', cuentaBancaria: '1234567891011', moneda: 'Dólar', tipoConvenio: 'Dato', tipoPeriodicidad: 'Dato', hacienda: true },
        { id: 6, tipoComision: 'Comisión3', codigo: '1234567891011', descripcion: 'Asegurado Voluntario', cuentaBancaria: '1234567891011', moneda: 'Dólar', tipoConvenio: 'Dato', tipoPeriodicidad: 'Dato', hacienda: false },
        { id: 7, tipoComision: 'Comision1', codigo: '789', descripcion: 'Planillas', cuentaBancaria: '1234567891011', moneda: 'Dólar', tipoConvenio: 'Dato', tipoPeriodicidad: 'Dato', hacienda: true },
        { id: 8, tipoComision: 'Comision2', codigo: '012', descripcion: 'Prueba', cuentaBancaria: '1234567891011', moneda: 'Dólar', tipoConvenio: 'Dato', tipoPeriodicidad: 'Dato', hacienda: false },

    ];

    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRows('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };

    const handleSave = () => {
        alert(successMessages.CHANGES_SAVED);
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
    };

    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };

    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };

    const handleDelete = () => {
        setIsDialogOpen(true);
        setIsDelete(true);
    };

    const handleGenerateReport = () => {
        alert(successMessages.DOWNLOAD_SUCCESSFUL);
    };

    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRows = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRows(selectedRows);
        console.log(selectedRows[0].descripcion);
    };

    return (
        <div className='content-wrapper'>
            <div className="wrapper-card">
                <h1>
                    Mantenimiento de Convenios Facturadores
                </h1>
                <div className='form-container'>
                    <div className="content">

                        <div className='form-container'>
                            <div className="title">
                                Parámetros
                            </div>
                            <div className="content-filter">
                                <div className="fila-form">
                                    <div className="columna-form">
                                        <label>
                                            Facturador:
                                        </label>
                                        <Tooltip title="Facturador">
                                            <select className='select-form' value={tipoFacturador} onChange={(e) => setTipoFacturador(e.target.value)}>
                                                <option value="">Seleccione el Facturador</option>
                                                <option value="moneda1">CCSS</option>
                                                <option value="moneda2">RACSA</option>
                                                <option value="moneda3">JASEC</option>
                                                <option value="moneda4">FACTURADOR</option>
                                            </select>
                                        </Tooltip>
                                    </div>
                                    <div className="button-filter">
                                        <Tooltip title="Buscar">
                                            <button className="action-button" onClick={handleGenerateReport}>Buscar</button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-container'>
                            <div className="title">
                                Lista de Convenios Facturadores
                            </div>
                            <div className="content">
                                <DataTable data={rows} columns={columnas} onRowSelectionChange={handleRowSelectionChange} />

                                <div className='button-action'>
                                    <Tooltip title="Agregar">
                                        <button className="action-button" onClick={handleAdd}>Agregar</button>
                                    </Tooltip>
                                    {isDialogOpen && isAdd && (
                                        <CustomDialog
                                            question={'Agregar Convenio Facturador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isEdit && (
                                        <CustomDialog
                                            question={'Modificar Convenio Facturador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} isEdit={isEdit} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isView && (
                                        <CustomDialog
                                            question={'Consultar Convenio Facturador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} isView={isView} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isDelete && (
                                        <AlertDialog
                                            question={"Eliminar Convenio Facturador"}
                                            message={confirmationMessages.DELETE_CONFIRMATION_CONVENIO}
                                            isOpen={isDialogOpen}
                                            onClose={handleCancel}
                                            onAccept={handleCancel}
                                            onCancel={handleCancel}
                                        />
                                    )}
                                    <Tooltip title="Generar Reporte">
                                        <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { MantenimientoConvenioFacturadores };