import {ContentMenu} from '../Card/ContentMenu';
import { CardList } from '../CardList/CardList';
import { Footer } from '../Footer/Footer';
import { useConectividadApi } from '../../service/service';
import { useSelector } from 'react-redux';
// eslint-disable-next-line react-hooks/rules-of-hooks
const { serverData }  = useConectividadApi();

const cards = [
    {tipo: 'Pagos', contenido: 'Menú de consulta y pagos'},
    {tipo: 'Reversión', contenido: 'Menú de reversión de pagos'},
    {tipo: 'Control de Depósito', contenido: 'Menú de Control de Depósitos'},
    {tipo: 'Recaudadores', contenido: 'Mantenimeinto de recaudadores'}
]

const Welcome = () => {
const session = useSelector(state => state.session);

    const handleGetData = async () => {
        debugger
        const options = {
            method: 'GET',
            url: '/users/age',
            headers: {
                Authorization: `Bearer ${session.credentials.accessToken}`
            }
          };
        try {
            await serverData(options);
        } catch (err) {
            console.error(err);
        }
    };

    return (
    <div className="wrapper">
        <button onClick={()=> handleGetData()}>Prueba</button>
        <div className="content">
            <div className="card2">
                <h1>
                    Bienvenidos a AdminConectividad
                </h1>
                <p className="text">El sistema de AdminConectividad es una herramienta que le permite gestionar el proceso de recaudación externa en linea</p>
                <hr />
                <h5 className="heading-service">Información de facturación</h5>
                <p className="detail-heading detail-heading-service-style">Cantidad de Facturadores: 4</p>
                <hr />

               <ol className="service-list">
                    <li className="list-detail">
                        <i className="bi"></i>
                        <p>Horario: 8:00am - 15:00pm</p>
                    </li>
                    <li className="list-detail">
                        <i className="bi "></i>
                        <p>Servicio al cliente: Soporte GBSYS S.A</p>
                    </li>            
                    <li className="list-detail">
                        <i className="bi "></i>
                        <p>Teléfono: 2286-0101</p>
                    </li>
                    <li className="list-detail">
                        <i className="bi "></i>
                        <p>Horario de Cierrre: 23:45:00pm</p>
                    </li>
                </ol>
            </div>

            <div className='cards-info'>
                <CardList>
                  {cards.map(card => (
                    <ContentMenu
                    key={card.tipo}
                    tipo={card.tipo}
                    contenido={card.contenido}
                    />
                  ))}  
                </CardList>
            </div>
        </div>
        <Footer />
    </div>
    );
};

export {Welcome};