import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { successMessages } from '../../messages/commonMessages.jsx';
import { useParametrosService } from '../../../service/parametros.js';


export const useParametros = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [data, setData] = useState([]);
    const { get } = useParametrosService();

    const getParametros = async () => {
        get().then((data) => {
            setData(data);
        })
    }
    
    const handleSave = () => {
        alert(successMessages.CHANGES_SAVED);
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        getParametros();
    };
    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRow('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };
    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };
    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };
    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRow = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRow(selectedRow);
        if (selectedRow.length > 0) {
            console.log(selectedRow[0].dscParametro);
        }
    };
    const columnas = [
        { field: 'dscParametro', headerName: 'Descripción', flex: 1 },
        { field: 'valParametro', headerName: 'Valor', flex: 1 },
        { field: 'categoria', headerName: 'Categoría', flex: 1 },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button id='btn-consultar' className="button-table" onClick={handleConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Modificar">
                        <button id='btn-editar' className="button-table" onClick={handleEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];
    return (
        {handleRowSelectionChange,  getParametros, handleSave, handleCloseDialog, 
        columnas,  isDialogOpen, isEdit , isView, isAdd,
        handleAdd, handleCancel, isDelete, selectedRow, data }
    )
};