import React from 'react';
import { Page, Document, Image, StyleSheet, View, Line, Text, Svg } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'

import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceDateTime from './InvoiceTime'
import logo from '../../logo2.png'
import logo2 from '../../logoEmpresa.png'

const title = {
    firstTitle: 'SISTEMA ADMIN CONECTIVIDAD',
    secondTitle: 'Banco BAC San José',
    thirdTitle: 'Reporte de Comisiones',
};

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 70,
        height: 45,
        textAlign: 'right',
    },
    logo2: {
        width: 80,
        height: 45,
        textAlign: 'left',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundRepeat: 'no-repeat',
        alignItems: 'center',
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    }
});

const Invoice = ({ invoice }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <Image style={styles.logo2} src={logo2} />
                <InvoiceTitle style={styles.logo} title={title} />
                <Image style={styles.logo} src={logo} />
            </View>
            <InvoiceItemsTable invoice={invoice} />
            <InvoiceDateTime />
            <View style={styles.pageNumber}>
                <Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} />
            </View>
        </Page>
    </Document>
);

export default Invoice