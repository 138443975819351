import * as React from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';

const DataTable = ({ data, columns, onRowSelectionChange }) => {

  const headerClassName = 'super-app-theme--header';

  const headerStyles = {
    [`& .${headerClassName}`]: {
      backgroundColor: '#f2f2f2',
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 'bold',
    },
  };
  
  return (
    <div style={{ height: 400, width: '100%', marginTop: '25px' }}>
      <DataGrid
        localeText={{
          ...esES.components.MuiDataGrid.defaultProps.localeText, 
          noRowsLabel: "No se encontraron registros"
        }}
        rows={data}
        columns={columns.map(column => ({
          ...column,
          headerClassName,
          minWidth: 160,
        }))}
        sx={headerStyles}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },

          },
        }}
        pageSizeOptions={[5, 10, 15, 20, 25]}
        onRowSelectionModelChange={(ids) => {
          if (onRowSelectionChange) {
            onRowSelectionChange(ids, data);
          }
        }}
        scrollbarSize={20}
        scroll={{ x: true }} 
      />
    </div>
  );
}

export { DataTable };