import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#FFFFFF'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#000000',
        backgroundColor: '#FFFFFF',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    description: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    int: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    perHa: {
        width: '35%'
    },
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.description}>Tipo Comisión</Text>
        <Text style={styles.qty}></Text>
        <Text style={styles.rate}></Text>
        <Text style={styles.int}></Text>
        <Text style={styles.perHa}>Pertenece Hacienda</Text>
    </View>
  );
  
  export default InvoiceTableHeader