import { Button } from '@mui/material';
import '../../App.css';
const ContentMenu = (props) => {

	return (
		<div className="card-container">
			<div className="card">
				<div className="front-content">
					<p>{props.tipo}</p>
				</div>
				<div className="content">
					<p className="heading">{props.contenido}</p>
					<Button variant="contained" sx={{bgcolor: 'white', color: '#1c2536', fontWeight:'bold'}}>Ir</Button>
				</div>
			</div>
		</div>
	);
}

export { ContentMenu };