import { useState } from 'react';
import { useOficinaService } from '../../../../service/oficina';
import { useOficina } from '../useOficina';
import { errorMessages } from '../../../../components/messages/commonMessages';

export const useFormulario = (onSave, selectedRow, render) => {
    const [tipoFacturador, setTipoFacturador] = useState(selectedRow !== '' ? selectedRow[0].facturador.idFacturador
    : '');
    const [nombre, setNombre] = useState(selectedRow !== '' ? selectedRow[0].nombre : '');
    const { post, put } = useOficinaService();
    const { getOficinas } = useOficina();

    let values = {};
    const addOficina = async (values) => {
        post(values);
    }
    const updateOficina = async (idOficina, values) => {
        put(idOficina, values);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (tipoFacturador && nombre) {
            values.nomOficina = nombre;
            values.facturador = {};
            values.facturador.idFacturador = tipoFacturador;
            
            if (render === 'Add') {
                await addOficina(values);
                alert('Oficina creada correctamente');
                await getOficinas(tipoFacturador);

            } else {
                let idOficina = selectedRow[0].idOficina;
                if (idOficina !== undefined) {
                    await updateOficina(idOficina, values);
                    alert('Oficina editada correctamente');
                    await getOficinas(tipoFacturador);
                }
            }
            onSave();
            // Limpiar el formulario después de agregar la oficina
            setNombre('');
            setTipoFacturador('');
        } else {
            alert('Por favor, verifique  todos los campos antes de agregar o actualizar la Oficina.');
        }
    };
    const updateNombre = async (value) => {
        setNombre(value)
    }
    const updateFacturador = async (value) => {
        setTipoFacturador(value)
    }

    return (
        { updateNombre, updateFacturador, handleSubmit, tipoFacturador, nombre }
    )
};