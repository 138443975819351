import React from 'react';
import { Link } from "react-router-dom";
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InboxIcon from '@mui/icons-material/Inbox';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShieldRoundedIcon from "@mui/icons-material/ShieldRounded";
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import ArticleIcon from '@mui/icons-material/Article';
import SourceIcon from '@mui/icons-material/Source';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import BuildIcon from '@mui/icons-material/Build';
import LocationCityIcon from '@mui/icons-material/LocationCity';

function Submenu({ text, isOpen, onClick }) {

    const listaPagos = [
        { nombre: 'Consulta y Pagos', icono: <ReceiptIcon className='icon-white' /> },
        { nombre: 'Reversión', icono: <InboxIcon className='icon-white' />},
    ];

    const listaMantenimiento = [
        { nombre: 'Comisiones', icono: <AssignmentIcon className='icon-white' /> },
        { nombre: 'Recaudadores', icono: <GroupIcon className='icon-white' />},
        { nombre: 'Oficinas', icono: <LocationCityIcon className='icon-white'/> },
        { nombre: 'Convenios de Facturadores', icono: <BusinessIcon className='icon-white'/> },
        { nombre: 'Llaves de Consulta', icono: <PlagiarismIcon className='icon-white'/> },
        { nombre: 'Agencia de Recaudadores', icono: <AccountBalanceRoundedIcon className='icon-white'/> },
        { nombre: 'Parámetros', icono: <ArticleIcon className='icon-white' /> },
        { nombre: 'Facturador', icono: <ReceiptIcon className='icon-white' />},
        { nombre: 'Códigos de Respuesta', icono: <SourceIcon className='icon-white'/> },
        { nombre: 'Configuración trama', icono: <BuildCircleIcon className='icon-white'/> },
        { nombre: 'Configuración trama Respuesta', icono: <BuildIcon className='icon-white'/> },
        { nombre: 'Mantenimientos Pagos Pendientes', icono: <SettingsIcon className='icon-white' /> },
    ];

    const listaRecaudacion = [
        { nombre: 'Control de Depósito', icono: <AssignmentIcon className='icon-white' /> },
        { nombre: 'Estadísticas', icono: <GroupIcon className='icon-white' />},
        { nombre: 'Reporte de Transacciones', icono: <BusinessIcon className='icon-white'/> },
        { nombre: 'Reporte de Pago por Usuario', icono: <PlagiarismIcon className='icon-white'/> },
        { nombre: 'Reporte de Reversiones', icono: <AccountBalanceRoundedIcon className='icon-white'/> },
        { nombre: 'Reporte de Rubros', icono: <ArticleIcon className='icon-white' /> },
        { nombre: 'Reporte de Pagos Pendientes', icono: <ReceiptIcon className='icon-white' />},
        { nombre: 'Reporte General de Totales', icono: <SourceIcon className='icon-white'/> },
        { nombre: 'Reporte Total de Convenios', icono: <BuildCircleIcon className='icon-white'/> },
        { nombre: 'Reporte Rubros CCSS', icono: <BuildIcon className='icon-white'/> },
        { nombre: 'Reporte Comisiones Recibos', icono: <SettingsIcon className='icon-white' /> },
        { nombre: 'Reimprimir Recibo', icono: <BuildIcon className='icon-white'/> },
        { nombre: 'Importación de Archivos', icono: <SettingsIcon className='icon-white' /> },
    ];

    const listaSeguridad = [
        { nombre: 'Cambio de Clave', icono: <AccountBoxIcon className='icon-white' /> },
        { nombre: 'Mantenimiento de Usuarios', icono: <ManageAccountsIcon className='icon-white' />},
        { nombre: 'Bitácora de Sucesos', icono: <ShieldRoundedIcon className='icon-white'/> },
        { nombre: 'Mantenimiento de Perfiles', icono: <PersonIcon className='icon-white'/> },
        { nombre: 'Mantenimiento Multiagencia', icono: <SettingsIcon className='icon-white'/> },
    ];
    
    return(
        <List>
            {text === 'Pagos' && (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {listaPagos.map((subtext) => (
                        <Link key={subtext.nombre} style={{ textDecoration: 'none', color: 'inherit' }} to={`/${subtext.nombre.toLowerCase()}`}>
                            <ListItem key={subtext.nombre} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                onClick={onClick}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: isOpen ? 'initial' : 'center',
                                    px: 4,
                                }}
                                >
                                <ListItemIcon
                                    sx={{
                                    minWidth: 0,
                                    mr: isOpen ? 3 : 'auto',
                                    justifyContent: 'center',
                                    }}
                                >
                                    {subtext.icono}
                                </ListItemIcon>
                                <ListItemText primary={subtext.nombre} sx={{ opacity: isOpen ? 1 : 0 }} title={subtext.nombre}/>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </Collapse>
            
            )}

            {text === 'Administración de Recaudación' && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {listaRecaudacion.map((subtext) => (
                        <Link key={subtext.nombre} style={{ textDecoration: 'none', color: 'inherit' }} to={`/${subtext.nombre.toLowerCase()}`}>
                            <ListItem key={subtext.nombre} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                onClick={onClick}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: isOpen ? 'initial' : 'center',
                                    px: 4,
                                }}
                                >
                                <ListItemIcon
                                    sx={{
                                    minWidth: 0,
                                    mr: isOpen ? 3 : 'auto',
                                    justifyContent: 'center',
                                    }}
                                >
                                    {subtext.icono}
                                </ListItemIcon>
                                <ListItemText primary={subtext.nombre} sx={{ opacity: isOpen ? 1 : 0 }} title={subtext.nombre}/>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))}
                    </List>
                </Collapse>
            )}

            {text === 'Mantenimientos' && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {listaMantenimiento.map((subtext) => (
                        <Link key={subtext.nombre} style={{ textDecoration: 'none', color: 'inherit' }} to={`/${subtext.nombre.toLowerCase()}`}>
                            <ListItem key={subtext.nombre} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                onClick={onClick}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: isOpen ? 'initial' : 'center',
                                    px: 4,
                                }}
                                >
                                <ListItemIcon
                                    sx={{
                                    minWidth: 0,
                                    mr: isOpen ? 3 : 'auto',
                                    justifyContent: 'center',
                                    }}
                                >
                                    {subtext.icono}
                                </ListItemIcon>
                                <ListItemText primary={subtext.nombre} sx={{ opacity: isOpen ? 1 : 0 }} title={subtext.nombre}/>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))}
                    </List>
                </Collapse>
            )}

            {text === 'Seguridad' && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {listaSeguridad.map((subtext) => (
                        <Link key={subtext.nombre} style={{ textDecoration: 'none', color: 'inherit' }} to={`/${subtext.nombre.toLowerCase()}`}>
                            <ListItem key={subtext.nombre} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                onClick={onClick}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: isOpen ? 'initial' : 'center',
                                    px: 4,
                                }}
                                >
                                <ListItemIcon
                                    sx={{
                                    minWidth: 0,
                                    mr: isOpen ? 3 : 'auto',
                                    justifyContent: 'center',
                                    }}
                                >
                                    {subtext.icono}
                                </ListItemIcon>
                                <ListItemText primary={subtext.nombre} sx={{ opacity: isOpen ? 1 : 0 }} title={subtext.nombre}/>
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))}
                    </List>
                </Collapse>
            )}
        </List>
    );
    
};

export {Submenu};