import React from 'react';
import './Alert.jsx';
import Tooltip from '@mui/material/Tooltip';

const AlertDialog = ({ question, message, isOpen, onClose, onAccept, onCancel }) => {
  return (
    <div>
      {isOpen && (
        <div className="custom-dialog-overlay">
          <div className="custom-dialog-content">
            <span onClick={onClose} className="close-button">
              &times;
            </span>
            <h3>{question}</h3>
            <hr />
            <p>{message}</p>
            <div className="button-container">
              <Tooltip title="Sí">
                <button className="action-button" onClick={onAccept}>
                  Sí
                </button>
              </Tooltip>
              <Tooltip title="No">
                <button className="action-button" onClick={onCancel}>
                  No
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { AlertDialog };