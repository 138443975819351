import '../../../../App.css'
import Tooltip from '@mui/material/Tooltip';
import { useFormulario } from './useFormulario.js';
import React, { useState, useEffect } from 'react';

const Formulario = ({ onSave, onCancel, selectedRow, render, mapFacturadores }) => {
    const { updateFacturador, updateNombre, handleSubmit,
        tipoFacturador, nombre } = useFormulario(onSave, selectedRow, render);

    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Facturador:
                    </label>
                    <Tooltip title="Facturador">
                        <select id="input-facturador" className='select-form' value={tipoFacturador} onChange={(e) => updateFacturador(e.target.value)} disabled={render === 'View' ? true : null}>
                            <option value="">Seleccione el facturador</option>
                            {mapFacturadores.map((option, index) => (
                                <option key={index} value={option.idFacturador}>{option.nomFacturadr}</option>
                            ))}
                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Nombre Oficina:
                    </label>
                    <Tooltip title="Nombre">
                        <input id="input-nombre" className='input-form' placeholder='Nombre' type="text" value={nombre} onChange={(e) => updateNombre(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="button-form">
                    {render !== 'View' && (
                        <Tooltip title="Aceptar">
                            <button id="btn-accion" className="action-button">Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button id="btn-cancelar" className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};
export { Formulario };