import React, { useState, useEffect } from 'react';
import { DataTable } from '../../Table/Table.jsx';
import { Footer } from '../../Footer/Footer.jsx';
import { successMessages, confirmationMessages } from '../../messages/commonMessages.jsx';
import { CustomDialog } from '../../messages/CustomDialog.jsx';
import '../../../App.css'
import { AlertDialog } from '../../messages/AlertDialog.jsx';
import Tooltip from '@mui/material/Tooltip';
import { Formulario } from './Formulario/Formulario.jsx';
import { useOficina } from './useOficina.js';
import Spinner from '../../spinner/LoadingSpinner.js';

function Alert(title, message) {
    return <CustomDialog title={title} message={message} />;
}

const MantenimientoOficina = () => {
    const { handleRowSelectionChange, getOficinas, handleSave, handleCloseDialog, updateFacturador,
        columnas, isDialogOpen, isEdit, isView, isAdd, tipoFacturador, facturadoresMap,
        handleAdd, handleCancel, handleConfirmationDelete, handleGenerateReport, handleSearch, isDelete, selectedRow, data, getFacturadores, loading } = useOficina();
    useEffect(() => {
        getFacturadores();
    }, []);
    return (
        <div className='content-wrapper'>
            {loading && <Spinner />}
            <div className="wrapper-card">
                <h1>
                    Mantenimiento de Oficinas
                </h1>
                <div className='form-container'>
                    <div className="content">
                        <div className='form-container'>
                            <div className="title">
                                Parámetros
                            </div>
                            <div className="content-filter">
                                <div className="fila-form">
                                    <div className="columna-form">
                                        <label>
                                            Facturador:
                                        </label>
                                        <Tooltip title="Facturador">
                                            <select className='select-form' value={tipoFacturador} onChange={(e) => updateFacturador(e.target.value)} >
                                                <option value="">Seleccione el facturador</option>
                                                {facturadoresMap && facturadoresMap.map((option, index) => (
                                                    <option key={index} value={option.idFacturador}>{option.nomFacturadr}</option>
                                                ))}
                                            </select>
                                        </Tooltip>
                                    </div>
                                    <div className="button-filter">
                                        <Tooltip title="Buscar">
                                            <button className="action-button" onClick={getOficinas}>Buscar</button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-container'>
                            <div className="title">
                                Lista de Oficinas
                            </div>
                            <div className="content">
                                <DataTable
                                    data={data && Array.isArray(data) ? data.map(row => ({ id: row.idOficina, nombre: row.nomOficina, tipoFacturador: row.facturador.nomFacturadr, ...row })) : []}
                                    columns={columnas}
                                    onRowSelectionChange={handleRowSelectionChange} >
                                    {data === null && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            Cargando datos...
                                        </div>
                                    )}
                                </DataTable>
                                <div className='button-action'>
                                    <Tooltip title="Agregar">
                                        <button className="action-button" onClick={handleAdd}>Agregar</button>
                                    </Tooltip>
                                    {isDialogOpen && isAdd && (
                                        <CustomDialog
                                            question={'Agregar Oficina'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRow} render={'Add'} mapFacturadores={facturadoresMap} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isEdit && (
                                        <CustomDialog
                                            question={'Modificar Oficina'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRow} render={'Edit'} mapFacturadores={facturadoresMap} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isView && (
                                        <CustomDialog
                                            question={'Consultar Oficina'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRow} render={'View'} mapFacturadores={facturadoresMap} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isDelete && (
                                        <AlertDialog
                                            question={"Eliminar Oficina"}
                                            message={confirmationMessages.DELETE_CONFIRMATION_OFICINA}
                                            isOpen={isDialogOpen}
                                            onClose={handleCancel}
                                            onAccept={handleConfirmationDelete}
                                            onCancel={handleCancel}
                                            render={'Delete'}
                                            parametro={selectedRow}
                                        />
                                    )}
                                    <Tooltip title="Generar Reporte">
                                        <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { MantenimientoOficina };