// Home.js

import { Route } from 'react-router-dom';
import MenuBar from '../Dashboard/Menu';

import LoginPage from '../LoginPage/LoginPage';
import { connect } from 'react-redux'
const mapStateToProps = function (state) {
    return { session: state.session }
}
function Home(props) {
    return (
        <>
            <Route path="/" component={props.session.isLoggedIn === true ? MenuBar : LoginPage} />
        </>
    );
};
export default connect(mapStateToProps)(Home);
