import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { DrawerHeader, Main } from '../../styles/Styles.jsx';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { AppBar, Drawer, Menu, MenuItem } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BuildIcon from '@mui/icons-material/Build';
import SecurityIcon from '@mui/icons-material/Security';
import HelpIcon from '@mui/icons-material/Help';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Submenu } from '../SubMenu/Submenu.jsx';
import logo from '../../logo2.png'
import { Welcome } from '../Welcome/Welcome';
import { PDFMain } from '../PDFReporte/PDFMain.jsx';
import { Link } from "react-router-dom";
import { MantenimientoConvenioFacturadores } from '../Mantenimientos/ConvenioFacturadores.jsx';
import { MantenimientoParametro } from '../Mantenimientos/Parametros/Parametro.jsx';
import { MantenimientoAgenciaRecaudadores } from '../Mantenimientos/AgenciaRecaudadores.jsx';
import { MantenimientoCodigoRespuesta } from '../Mantenimientos/CodigoRespuesta.jsx';
import { MantenimientoOficina } from '../Mantenimientos/Oficina/Oficina.jsx';
import { useSelector } from 'react-redux';
import cognitoUtils from '../../lib/cognitoUtils.js'

//let urlAuth = process.env.REACT_APP_COGNITO_OAUTH;
//let urlReactApp = process.env.REACT_APP_REDIRECT_URI;
//let cliendId = process.env.REACT_APP_COGNITO_CLIENT_ID;

const drawerWidth = 240;
const MenuBar = (pathname) => {

  function render() {
    switch (pathname.location.pathname) {
      case '/inicio':
        return <Welcome></Welcome>;
      case '/reporte de transacciones':
        return <PDFMain></PDFMain>;
      case '/convenios de facturadores':
        return <MantenimientoConvenioFacturadores></MantenimientoConvenioFacturadores>;
      case '/parámetros':
        return <MantenimientoParametro></MantenimientoParametro>;
      case '/agencia de recaudadores':
        return <MantenimientoAgenciaRecaudadores></MantenimientoAgenciaRecaudadores>;
      case '/códigos de respuesta':
        return <MantenimientoCodigoRespuesta></MantenimientoCodigoRespuesta>;
      case '/oficinas':
        return <MantenimientoOficina></MantenimientoOficina>;
      default:
        return <Welcome></Welcome>;
    }
  }
  const session = useSelector(state => state.session);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [username, setUsername] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSubmenus, setOpenSubmenus] = React.useState({});
  React.useEffect( () => {
        if (session.isLoggedIn === true) {
          setUsername(session.user.userName);
        }
    },[username]);
  const listaDeElementos = [
    { nombre: 'Inicio', icono: <HomeIcon className='icon-white' /> },
    { nombre: 'Pagos', icono: <PaymentIcon className='icon-white' /> },
    { nombre: 'Administración de Recaudación', icono: <ReceiptIcon className='icon-white' /> },
    { nombre: 'Mantenimientos', icono: <BuildIcon className='icon-white' /> },
    { nombre: 'Seguridad', icono: <SecurityIcon className='icon-white' /> },
    { nombre: 'Ayuda', icono: <HelpIcon className='icon-white' /> },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    debugger;
   let url = cognitoUtils.signOutCognitoSession();
   console.log(url);

  };
  const drawer = (
    <>
      <DrawerHeader>
        <img alt="Logo" src={logo} className='img-menubar' />
      </DrawerHeader>
      <Divider />
      <List>
        {listaDeElementos.map((text) => (
          <ListItem key={text.nombre} onClick={() => handleSubmenuClick(text.nombre)} disablePadding sx={{ display: 'block', }}>
            <Link key={text.nombre} style={{ textDecoration: 'none', color: 'inherit' }} to={text.nombre === 'Inicio' || text.nombre === 'Ayuda' ? `/${text.nombre.toLowerCase()}` : '#'}>
              <ListItemButton sx={{minHeight: 48,justifyContent: 'initial', px: 2.5,}}>
                <ListItemIcon sx={{ minWidth: 0,mr: 3,justifyContent: 'center', }}>
                  {text.icono}
                </ListItemIcon>
                <ListItemText primary={text.nombre} sx={{ opacity: 1,whiteSpace: 'nowrap',textOverflow: 'clip',overflow: 'hidden', }}
                  title={text.nombre} />
                {text.nombre === 'Pagos' && (
                  openSubmenus[text.nombre] ? (<ExpandLessIcon sx={{ flexGrow: 1 }} />) : (<ExpandMoreIcon />)
                )}
                {text.nombre === 'Administración de Recaudación' && (
                  openSubmenus[text.nombre] ? (<ExpandLessIcon sx={{ flexGrow: 1 }} />) : (<ExpandMoreIcon />)
                )}
                {text.nombre === 'Mantenimientos' && (
                  openSubmenus[text.nombre] ? (<ExpandLessIcon sx={{ flexGrow: 1 }} />) : (<ExpandMoreIcon />)
                )}
                {text.nombre === 'Seguridad' && (
                  openSubmenus[text.nombre] ? (<ExpandLessIcon sx={{ flexGrow: 1 }} />) : (<ExpandMoreIcon />)
                )}
              </ListItemButton>
            </Link>
            <Submenu text={text.nombre} isOpen={openSubmenus[text.nombre]} />
          </ListItem>
        ))}
      </List>
    </>
  );

  const handleSubmenuClick = (nombre) => {
    setOpenSubmenus((prevOpenSubmenus) => ({
      ...prevOpenSubmenus,
      [nombre]: !prevOpenSubmenus[nombre],
    }));
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar className='appbar-style' position='fixed' sx={{width: { sm: `calc(100% - ${drawerWidth}px)` },ml: { sm: `${drawerWidth}px` },}}>
        <Toolbar className='appbar-style'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Conectividad
          </Typography>
          <div>
            {username}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Perfil</MenuItem>
              <MenuItem onClick={handleClose}>Mi cuenta</MenuItem>
              <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box', width: drawerWidth,
              backgroundColor: '#213056 ', color: 'white',
              WebkitOverflowScrolling: 'touch',
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '6px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#213056 ',
              },
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box', width: drawerWidth,
              backgroundColor: '#213056', color: 'white',
              WebkitOverflowScrolling: 'touch',
              '&::-webkit-scrollbar': {
                width: 10,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '6px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#213056 ',
              },
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Main sx={{ margin: '0 auto' }} open={mobileOpen}>
        <DrawerHeader />
        <section>
          {render()}
        </section>
      </Main>
    </Box>
  );
}

MenuBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default MenuBar;