import '../../../../App.css'
import Tooltip from '@mui/material/Tooltip';
import { useFormulario } from './useFormulario.js';

const Formulario = ({ onSave, onCancel, parametro, render }) => {
    const {updateDescripcion, updaValor, updateCategoria,handleSubmit, 
    descripcion, valor, categoria} = useFormulario(onSave, onCancel, parametro, render);
    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Categoría:
                    </label>
                    <Tooltip title="Categoría">
                        <input id="input-categoria" className='input-form' type="text" placeholder='Categoría' value={categoria} onChange={(e) => updateCategoria(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Valor:
                    </label>
                    <Tooltip title="Valor">
                        <input id="input-valor" className='input-form' type="text" placeholder='Valor' value={valor} onChange={(e) => updaValor(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>

            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Descripción:
                    </label>
                    <Tooltip title="Descripción">
                        <input id="input-descripcion" className='input-form' type="text" placeholder='Descripción' value={descripcion} onChange={(e) => updateDescripcion(e.target.value)} disabled={render === 'View' ? true : null} />
                    </Tooltip>
                </div>
            </div>

            <div className="fila-form">
                <div className="button-form">
                    {render !== 'View' && (
                        <Tooltip title="Aceptar">
                            <button id="btn-accion" className="action-button">Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button id="btn-cancelar" className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};
export { Formulario };