import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
const today = new Date();
const month = today.getMonth() + 1;
const year = today.getFullYear();
const date = today.getDate();
const currentDate = date + "/" + month + "/" + year;

const options = { timeStyle: 'short', hour12: true};
const timeString = today.toLocaleTimeString('es-CR', options);
const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 12
    },
    reportTitle:{
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
        margin: 3
    }
  });


  const InvoiceDateTime = ({}) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{currentDate}</Text>
        <Text style={styles.reportTitle}>{timeString}</Text>
    </View>
  );
  
  export default InvoiceDateTime