import './Alerts.css';

const CustomDialog = ({ question, message }) => {
    return (
        <div>
            <div className="custom-dialog-overlay">
                <div className="custom-dialog-content">
                    <h3> {question} </h3>
                    <hr />
                    <div className="content">
                        {message}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { CustomDialog };