import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableHeaderTitle from './InvoiceTableHeaderTitle'


const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#000000',
    },
});

  const InvoiceItemsTable = ({invoice}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeaderTitle title = 'Comisión: Primer comision'/>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.items} />
        <InvoiceTableHeaderTitle title = 'Comisión: Comisión Dólar'/>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.itemsDollar} />
        <InvoiceTableHeaderTitle title = 'Comisión: Comisión Convenios'/>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.itemsConvenio} />
    </View>
  );
  
  export default InvoiceItemsTable