import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import axios from 'axios';
import Callback from './routes/Callback'
import { createBrowserHistory } from 'history'
import Home from "./components/Home/Home";

const history = createBrowserHistory()
axios.defaults.withCredentials = true;

function App() {
    return (
        <div className="App">
            <Router history={history}>
                <Route exact path="/" component={Home} />
                <Route exact path="/parámetros" component={Home} />
                <Route exact path="/oficinas" component={Home} />
                <Route exact path="/reporte de transacciones" component={Home} />
                <Route exact path="/inicio" component={Home} />
                <Route exact path="/login" component={Callback} />
            </Router>
        </div>
    );
}

export default App;

