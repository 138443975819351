import React from 'react';
import { Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import MyCustomFont from '../../fonts/Anton-Regular.ttf';


Font.register({
    family: 'AntonFamily',
    src: MyCustomFont
  })

const styles = StyleSheet.create({
    reportTitle: {
        fontSize: 14,
        letterSpacing: 1,
        textAlign: 'justify',
        textTransform: 'uppercase',
        display:'flex',
        alignSelf:'center',

    },
    reportMainTitle: {
        fontWeight: 'heavy',
        letterSpacing: 1,
        fontSize: 14,
        fontFamily: "AntonFamily",
        textAlign: 'right',
        display:'flex',
        alignSelf:'center',

    }
});


const InvoiceTitle = ({ title }) => (
    <View>
        <Text style={styles.reportMainTitle}>{title.firstTitle}</Text>
        <Text style={styles.reportTitle}>{title.secondTitle}</Text>
        <Text style={styles.reportTitle}>{title.thirdTitle}</Text>
    </View>
);

export default InvoiceTitle