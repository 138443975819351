import React, { useState } from 'react';
import { DataTable } from '../Table/Table.jsx';
import { Footer } from '../Footer/Footer';
import { successMessages, confirmationMessages } from '../messages/commonMessages';
import { CustomDialog } from '../messages/CustomDialog';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../App.css'
import { AlertDialog } from '../messages/AlertDialog.jsx';
import Tooltip from '@mui/material/Tooltip';


const Formulario = ({ onSave, onCancel, selectedRow, isView, isEdit }) => {
    const [tipoRecaudador, setTipoRecaudador] = useState(selectedRow !== '' ? selectedRow[0].tipoRecaudador : '');
    const [convenio, setConvenio] = useState(selectedRow !== '' ? selectedRow[0].convenio : '');
    const [codigoInterno, setCodigoInterno] = useState(selectedRow !== '' ? selectedRow[0].codigoInterno : '');
    const [codigoAgencia, setCodigoAgencia] = useState(selectedRow !== '' ? selectedRow[0].codigoAgencia : '');
    const [nombre, setNombre] = useState(selectedRow !== '' ? selectedRow[0].nombre : '');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (convenio && codigoInterno && codigoAgencia && nombre && tipoRecaudador) {

            alert(successMessages.OPERATION_SUCCESS);

            setConvenio('');
            setCodigoInterno('');
            setCodigoAgencia('');
            setNombre('');
            setTipoRecaudador('');
        } else {
            alert('Por favor, complete todos los campos antes de agregar la agencia de recaudadores.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Recaudador:
                    </label>
                    <Tooltip title="Recaudador">
                        <select className='select-form' value={tipoRecaudador} onChange={(e) => setTipoRecaudador(e.target.value)} disabled={isView || isEdit ? true : null}>
                            <option value="">Seleccione</option>
                            <option value="moneda1">CCSS</option>
                            <option value="moneda2">RACSA</option>
                            <option value="moneda3">JASEC</option>
                            <option value="moneda4">FACTURADOR</option>
                        </select>
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Nombre:
                    </label>
                    <Tooltip title="Nombre">
                        <input className='input-form' type="text" placeholder='Nombre' value={nombre} onChange={(e) => setNombre(e.target.value)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="columna-form">
                    <label>
                        Código Agencia:
                    </label>
                    <Tooltip title="Código Agencia">
                        <input className='input-form' type="text" placeholder='Código Agencia' value={codigoAgencia} onChange={(e) => setCodigoAgencia(e.target.value)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
                <div className="columna-form">
                    <label>
                        Código Interno:
                    </label>
                    <Tooltip title="Código Interno">
                        <input className='input-form' type="text" placeholder='Código Interno' value={codigoInterno} onChange={(e) => setCodigoInterno(e.target.value)} disabled={isView ? true : null} />
                    </Tooltip>
                </div>
            </div>
            <div className="fila-form">
                <div className="button-form">
                    {!isView && (
                        <Tooltip title="Aceptar">
                            <button className="action-button" onClick={onSave}>Aceptar</button>
                        </Tooltip>
                    )}
                    <Tooltip title="Cancelar">
                        <button className="action-button" onClick={onCancel}>Cancelar</button>
                    </Tooltip>
                </div>
            </div>
        </form>
    );
};

const MantenimientoAgenciaRecaudadores = () => {
    const [selectedRows, setSelectedRows] = useState();
    const [tipoRecaudador, setTipoRecaudador] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();

    const columnas = [
        { field: 'consecutivo', headerName: 'Consecutivo', flex: 1, },
        { field: 'nombre', headerName: 'Nombre', flex: 1 },
        { field: 'codigoAgencia', headerName: 'Código Agencia', flex: 1 },
        { field: 'codigoInterno', headerName: 'Código Interno', flex: 1 },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button className="button-table" onClick={handleConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Modificar">
                        <button className="button-table" onClick={handleEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <button className="button-table" onClick={handleDelete}>
                            <DeleteIcon />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const rows = [
        { id: 1, codigoAgencia: 'Comisión Convenios', codigoInterno: '456', consecutivo: 'Trabajador Independiente', nombre: '1234567891011' },
        { id: 2, codigoAgencia: 'Comisión Convenios', codigoInterno: '123', consecutivo: 'Asegurado Voluntario', nombre: '1234567891011' },
        { id: 3, codigoAgencia: 'Comisión Convenios', codigoInterno: '789', consecutivo: 'Planillas', nombre: '1234567891011' },
        { id: 4, codigoAgencia: 'test1', codigoInterno: '012', consecutivo: 'Prueba', nombre: '1234567891011' },
        { id: 5, codigoAgencia: 'Comisión Convenios', codigoInterno: '456', consecutivo: 'Trabajador Independiente', nombre: '1234567891011' },
        { id: 6, codigoAgencia: 'Comisión Convenios', codigoInterno: '1234567891011', consecutivo: 'Asegurado Voluntario', nombre: '1234567891011' },
        { id: 7, codigoAgencia: 'Comisión Convenios', codigoInterno: '789', consecutivo: 'Planillas', nombre: '1234567891011' },
        { id: 8, codigoAgencia: 'test1', codigoInterno: '012', consecutivo: 'Prueba', nombre: '1234567891011' },

    ];

    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRows('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };

    const handleSave = () => {
        alert(successMessages.CHANGES_SAVED);
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
    };

    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };

    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };

    const handleDelete = () => {
        setIsDialogOpen(true);
        setIsDelete(true);
    };

    const handleGenerateReport = () => {
        alert(successMessages.DOWNLOAD_SUCCESSFUL);
    };

    const handleRowSelectionChange = (selectedRowIds, data) => {
        const selectedIDs = new Set(selectedRowIds);
        const selectedRows = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRows(selectedRows);
        console.log(selectedRows[0].consecutivo);
    };

    return (
        <div className='content-wrapper'>
            <div className="wrapper-card">
                <h1>
                    Mantenimiento Agencias de Recaudadores
                </h1>
                <div className='form-container'>
                    <div className="content">
                        <div className='form-container'>
                            <div className="title">
                                Parámetros
                            </div>
                            <div className="content-filter">
                                <div className="fila-form">
                                    <div className="columna-form">
                                        <label>
                                            Recaudador:
                                        </label>
                                        <Tooltip title="Recaudador">
                                            <select className='select-form' value={tipoRecaudador} onChange={(e) => setTipoRecaudador(e.target.value)}>
                                                <option value="">Seleccione el Recaudador</option>
                                                <option value="moneda1">CCSS</option>
                                                <option value="moneda2">RACSA</option>
                                                <option value="moneda3">JASEC</option>
                                                <option value="moneda4">FACTURADOR</option>
                                            </select>
                                        </Tooltip>
                                    </div>
                                    <div className="button-filter">
                                        <Tooltip title="Buscar">
                                            <button className="action-button" onClick={handleGenerateReport}>Buscar</button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-container'>
                            <div className="title">
                                Lista de Agencias Recaudadores
                            </div>
                            <div className="content">
                                <DataTable data={rows} columns={columnas} onRowSelectionChange={handleRowSelectionChange} />

                                <div className='button-action'>
                                    <Tooltip title="Agregar">
                                        <button className="action-button" onClick={handleAdd}>Agregar</button>
                                    </Tooltip>
                                    {isDialogOpen && isAdd && (
                                        <CustomDialog
                                            question={'Agregar Agencia Recaudador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isEdit && (
                                        <CustomDialog
                                            question={'Modificar Agencia Recaudador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} isEdit={isEdit} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isView && (
                                        <CustomDialog
                                            question={'Consultar Agencia Recaudador'}
                                            message={<Formulario onSave={handleSave} onCancel={handleCancel} selectedRow={selectedRows} isView={isView} />}
                                            onClose={handleCloseDialog}
                                        />
                                    )}
                                    {isDialogOpen && isDelete && (
                                        <AlertDialog
                                            question={"Eliminar Agencia Recaudador"}
                                            message={confirmationMessages.DELETE_CONFIRMATION_AGENCIA}
                                            isOpen={isDialogOpen}
                                            onClose={handleCancel}
                                            onAccept={handleCancel}
                                            onCancel={handleCancel}
                                        />
                                    )}
                                    <Tooltip title="Generar Reporte">
                                        <button className="action-button" onClick={handleGenerateReport}>Generar Reporte</button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export { MantenimientoAgenciaRecaudadores };