import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Logo from "../../logoGBSYS_footer.png"

function Copyright() {
  return (
    <Typography variant="body2">
      {'Copyright © '}
      <Link color="inherit" href="https://gbsys.com">
        GBSYS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function Footer() {
  return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '30vh',
        }}
      >
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
          }}
        >
          <Container maxWidth="sm">
            <Box display="flex" flexWrap={"row wrap"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
              <img src={Logo} style={{maxWidth:'100px'}} alt="gbsys" border="0" />
              <Copyright />
            </Box>
          </Container>
        </Box>
      </Box>
  );
}